import { Env } from '@app/env';

export const environment: Env = {
  production: true,
  name: 'prod',
  region: 'us-east-1',
  version: '63.22.0',

  defaultOffsetFromUtc: 8,
  fixedDateMs: '2022-05-26T02:00:00Z',

  shortDomain: 'bscr.io',
  publicBucket: 'brightscrip-public-prod',
  patientsBucket: 'brightscrip-patients-prod',

  captchaApiKey:
    'qO+fokBA5Oe5M5sXhC3V2lpYKEiESD8dZV9hY6jRR39b7bAgpJ8Lt4hC758xTMXIgTkOjyC4z1s4wOOnParPBvQE23Zs5MitBKeISHIr/dhQaTY8t1tr2hUSbirmCIlrIz2ek7xZXYd7GTgmNxNyl3FMwG7G2BUjbZQuoICy3ORk8nQQZludMzH0Ro8XVYSfDL/rEPc5LRO3OFUf2U4+Fsyjjs3FUzJPGJsLw8q+yea4N3V+Bv3pHiHW7LNLZve6rY4pGtMfYaHs6EuMI//OPaUGUUpdjqlQhHiYUWKqemnSXDw7G/yimSluRBrssPzcGppp0CAdT9Or5IxINDERfpT8Is2dUlIHF3i+7DCc7GWwSQEEybEh8t7p7iperkFGcw93PIM6PCItzZCLzjegwVpY9zbSoVdW5a4FM4w5iA3TlO+3JeNbAct3gvFXAJwgt2GJGyZ19V0WqFaDrlUcsrmyffW4UnHI3n/buO+uBDVra+JZJSlrdvkYy9c+oILD+NGFFLEQmYYuLoC5iOcUZU2R1cyh57reaD0jb660+fYhIsXod/kTBIAdyIZfxy2/c2OYUYXwAbam0MywLWnqQG1eVHadhM8a9Hz2yk5YAwzQ3T7jHjDenNENzjV3SG6+J5yXcZn0TUVBOz5Iu9Ae1Kx52/URQj1xA5XK1fbb4/k=_0_1',
  captchaUrl: 'https://4afb4d831fff.us-east-1.captcha-sdk.awswaf.com/4afb4d831fff/jsapi.js',
  captchaEnabled: true,
  mixpanelProjectToken: '996d0a70f7d31be5643629772bf76594',
  growthbook: {
    clientKey: 'sdk-1jPWGg66XatSordf',
    enabledDevMode: false,
  },
  posthog: {
    token: 'phc_pD0bH5FahBXTalFS8NtlkCu23zbPr2tjVd6dkPmw2VJ',
    host: 'https://app.posthog.com',
  },
  sentry: {
    enabled: true,
    dsn: 'https://6ff654c859384ce4969c5266fab18827@sentry.io/1509087',
  },

  cognito: {
    userpoolId: 'us-east-1_LX27rNbrW',
    appClientId: '4ml6jfo7in3m6ia258nci7bs89',
  },

  graphql: {
    endpoint: 'https://hkjp7y4ccfeh3k3f5ibnydqdv4.appsync-api.us-east-1.amazonaws.com/graphql',
    region: 'us-east-1',
  },

  openSearchUrl: 'https://mbbq5u6k8g.execute-api.us-east-1.amazonaws.com/prod/proxy',

  codeMainUrl: 'https://1lu6o8yt24.execute-api.us-east-1.amazonaws.com/prod',
  codeRequestUrl: 'https://teu7fgop62.execute-api.us-east-1.amazonaws.com/prod',
  codeRequestAggregatesUrl: 'https://8czwszxk5c.execute-api.us-east-1.amazonaws.com/prod',
  dataDeliveryUrl: 'https://data-delivery-api.brightscrip.com/prod',
  devOpsUrl: 'https://tbdmr3m2p1.execute-api.us-east-1.amazonaws.com/prod',
  documentsUrl: 'https://pvxhogt3k1.execute-api.us-east-1.amazonaws.com/prod',
  etlUrl: 'https://8dm41v1d70.execute-api.us-east-1.amazonaws.com/prod',
  faxUrl: 'https://4lnfpq6d66.execute-api.us-east-1.amazonaws.com/prod',
  identityUrl: 'https://owd022ozi6.execute-api.us-east-1.amazonaws.com/prod',
  inboundsCallsUrl: 'https://qewy33554j.execute-api.us-east-1.amazonaws.com/prod',
  notificationUrl: 'https://cgtmywwym1.execute-api.us-east-1.amazonaws.com/prod',
  pharmacyUrl: 'https://7cq4kr3mmk.execute-api.us-east-1.amazonaws.com/prod',
  prescriberUrl: 'https://prescribers-api.brightscrip.com/prod',
  rxConnectUrl: 'https://rxconnect-api.brightscrip.com/prod',
  sftpUrl: 'https://ik08j3ke18.execute-api.us-east-1.amazonaws.com/prod',
  surveillanceUrl: 'https://surveillance-api.brightscrip.com/prod',
  supportUrl: 'https://thuj870468.execute-api.us-east-1.amazonaws.com/prod',

  aggregatesUrl: 'https://ocs49uom4k.execute-api.us-east-1.amazonaws.com/prod',
  copayAssistanceServiceUrl: 'https://srovcbnvdi.execute-api.us-east-1.amazonaws.com/prod',
  copayUrl: 'https://srovcbnvdi.execute-api.us-east-1.amazonaws.com/prod',
  masterDataUrl: 'https://f4yocsk3s3.execute-api.us-east-1.amazonaws.com/prod',
  paUrl: 'https://z577kn1lz6.execute-api.us-east-1.amazonaws.com/prod',
  rxTriageServiceUrl: 'https://1ypvjdu5g0.execute-api.us-east-1.amazonaws.com/prod/',
  tableEditorServiceUrl: 'https://g77c7getai.execute-api.us-east-1.amazonaws.com/prod/',
  tokenActionUrl: 'https://sre9kv88m4.execute-api.us-east-1.amazonaws.com/prod',
  triageDeltaServiceUrl: 'https://vu9zarjae5.execute-api.us-east-1.amazonaws.com/prod/',
};
